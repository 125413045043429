import type { CustomComponentProps } from '@aurora/external-types/externalContext';
import Loading from '@aurora/shared-client/components/common/Loading/Loading';
import SwitchBranchContext from '@aurora/shared-client/components/context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import type { FallbackComponentProps } from '@aurora/shared-client/components/error/ErrorBoundary/ErrorBoundary';
import { getComponentContextProps } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import type { ComponentProp } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import type { I18n } from '@aurora/shared-types/texts';
import TenantHelper from '@aurora/shared-utils/helpers/TenantHelper';
import { getLog } from '@aurora/shared-utils/log';
import { importRemote } from '@module-federation/utilities';
import dynamic from 'next/dynamic';
import React, { lazy, Suspense, useContext, useMemo } from 'react';
import useExternalContext from '../useExternalContext';
import { canUseDOM } from 'exenv';

const log = getLog(module);

const ErrorBoundary = dynamic(
  () => import('@aurora/shared-client/components/error/ErrorBoundary/ErrorBoundary')
);
const QuiltComponentError = dynamic(() => import('../../page/QuiltComponent/QuiltComponentError'));

export interface ExternalComponentProps {
  /**
   * The id of the component/
   */
  id: string;
  /**
   * Specifies any props to pass to the custom component.
   */
  customComponentProps?: Array<ComponentProp>;

  /**
   * Functions for loading translated messages.
   */
  i18n: Omit<I18n<unknown, unknown>, 'loading' | 'refetch'>;

  /**
   * Is this a local override of the component?
   */
  localOverride: boolean;

  /**
   * Is the component in edit mode?
   */
  isEditMode: boolean;
}

const ExternalComponent: React.FC<ExternalComponentProps> = ({
  id,
  localOverride,
  customComponentProps,
  i18n,
  isEditMode
}) => {
  const tenant = useContext(TenantContext);
  const { branchName } = useContext(SwitchBranchContext);
  const loadComponent = useMemo(async (): Promise<{
    default: React.ComponentType<CustomComponentProps>;
  }> => {
    const { moduleFederationHost, repoName, mainBranchName } = tenant.publicConfig;
    if (moduleFederationHost) {
      const mfBranchName = branchName ?? mainBranchName;
      const devUrl = process.env.NEXT_PUBLIC_MODULE_FEDERATION_HOST_LOCAL;
      const effectiveUrl = localOverride
        ? devUrl
        : `${moduleFederationHost}/${repoName}/${encodeURIComponent(mfBranchName)}/mf`;
      try {
        const remoteName = id.split('.').pop();
        log.info('Loading custom component: %s from %s', remoteName, effectiveUrl);
        return importRemote({
          url: `${effectiveUrl}/_next/static/${canUseDOM ? 'chunks' : 'ssr'}`,
          scope: 'auroraComponents' + (localOverride ? 'Local' : ''),
          module: `./${remoteName}`
        });
      } catch (error) {
        log.error(error, 'Error loading custom component: %s from %s', id, effectiveUrl);
      }
    } else if (!moduleFederationHost) {
      log.warn('No host specified for custom component: %s', id);
      throw new Error('No mf host specified');
    }
  }, [id, branchName, localOverride, tenant]);

  const props = useMemo(() => {
    return getComponentContextProps(customComponentProps);
  }, [customComponentProps]);

  const RemoteComponent = useMemo(
    () => lazy<React.ComponentType<CustomComponentProps>>(() => loadComponent),
    [loadComponent]
  );

  const ErrorFallback: React.FC<React.PropsWithChildren<FallbackComponentProps>> = ({ error }) => {
    return isEditMode || TenantHelper.isDevelopmentPhase(tenant) ? (
      <QuiltComponentError componentId={id} errorMessage={error.message} />
    ) : null;
  };

  const externalContext = useExternalContext({ id }, i18n);

  return (
    <ErrorBoundary fallbackComponent={ErrorFallback}>
      <Suspense fallback={<Loading />}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <RemoteComponent {...props} auroraContext={externalContext} />
      </Suspense>
    </ErrorBoundary>
  );
};

/**
 * This is a wrapper around the ExternalComponent logic until the module federation "importRemote" function supports
 * SSR.
 * @param props
 * @constructor
 */
const ExternalComponentWrapper: React.FC<ExternalComponentProps> = props => {
  if (!canUseDOM) {
    return null;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ExternalComponent {...props} />;
};

export default ExternalComponentWrapper;
