import type { MutationHookOptions, QueryHookOptions, TypedDocumentNode } from '@apollo/client';
import type { OperationVariables } from '@apollo/client/core';
import type { SuspenseQueryHookOptions } from '@apollo/client/react/types/types';
import type {
  ExternalContext,
  ExternalContextMessage,
  ExternalContextNode,
  ExternalContextUser,
  ExternalPageContext,
  ExternalUtils
} from '@aurora/external-types/externalContext';
import type { EndUserPages, EndUserQueryParams } from '@aurora/external-types/page/pages';
import type { RouterAndLink } from '@aurora/external-types/page/router';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import type { ToastContextInterface } from '@aurora/shared-client/components/context/ToastContext/ToastContext';
import ToastContext from '@aurora/shared-client/components/context/ToastContext/ToastContext';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useSuspenseQueryWithTracing from '@aurora/shared-client/components/useSuspenseQueryWithTracing';
import {
  deleteFromInternalApi,
  getFromInternalApi,
  postToInternalApi
} from '@aurora/shared-client/helpers/ApiHelper';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { QuiltComponent } from '@aurora/shared-generated/types/graphql-schema-types';
import type { I18n } from '@aurora/shared-types/texts';
import { getLog } from '@aurora/shared-utils/log';
import type { DocumentNode } from 'graphql';
import { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import components from './components/index';

function useExternalContext(
  component: Pick<QuiltComponent, 'id'>,
  i18n: Omit<I18n<unknown, unknown>, 'loading' | 'refetch'>
): ExternalContext {
  const tenant = useContext(TenantContext);
  const { community, authUser, contextNode, contextMessage, contextUser } = useContext(AppContext);
  const toastContext: ToastContextInterface = useContext(ToastContext);
  const endUserRoutes = useEndUserRoutes();

  // this has been fixed in @module-federation/nextjs-mf as of V8.8, so we can just no-op here
  const assetUrl = (url: string): string => {
    return url;
  };

  function useQueryWrapper<T, V extends OperationVariables>(
    query: DocumentNode | TypedDocumentNode<T, V>,
    options?: QueryHookOptions<T, V>
  ) {
    const { ready, ...rest } = useQueryWithTracing(module, query, options);
    return rest;
  }

  function useMutationWrapper<TData = unknown, TVariables = OperationVariables>(
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: MutationHookOptions<TData, TVariables>,
    switchUserIdOverride?: number
  ) {
    return useMutationWithTracing(module, mutation, options, switchUserIdOverride);
  }

  function useSuspenseQueryWrapper<TData = unknown, TVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: SuspenseQueryHookOptions<TData, TVariables>
  ) {
    return useSuspenseQueryWithTracing(module, query, options);
  }

  const utils: ExternalUtils = {
    log: getLog(component.id),
    i18n,
    useClassNameMapper: (localClassNameMap?: Record<string, string>) => {
      return useClassNameMapper(localClassNameMap) as (...classes: unknown[]) => string | undefined;
    },
    useQuery: useQueryWrapper,
    useSuspenseQuery: useSuspenseQueryWrapper,
    useMutation: useMutationWrapper,
    getFromInternalApi,
    postToInternalApi,
    deleteFromInternalApi,
    assetUrl,
    ...toastContext
  };

  const pageContext: ExternalPageContext = {
    tenant,
    endUserRoutes: endUserRoutes as RouterAndLink<EndUserPages, EndUserQueryParams>,
    community,
    authUser,
    contextNode: contextNode as unknown as ExternalContextNode,
    contextMessage: contextMessage as unknown as ExternalContextMessage,
    contextUser: contextUser as ExternalContextUser
  };

  return {
    components,
    utils,
    pageContext
  };
}

export default useExternalContext;
